<template>
  <b-sidebar
    id="new-document-sidebar"
    :visible="isNewDocumentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-new-document-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar novo termo 
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <validation-provider
            v-slot="{ errors }"
            name="url"
            vid="file"
            rules="required"
            
          >
            <b-form-select v-model="form.type_url">
              <option value="download">PDF</option>
              <option value="external_link">URL externa</option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <template v-if="form.type_url === 'download'">
            <validation-provider
              v-slot="{ errors }"
              name="url"
              vid="file"
              rules="required"
              
            >
              <b-form-file
                type="file"
                @change="handleFileUpload"
                accept=".pdf"
                v-model="form.url"
                class="mt-2"
                placeholder="Selecione o PDF"
                drop-placeholder="solte o arquivo aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{ fileError }}</small>
            </validation-provider>
          </template>
          <template v-else-if="form.type_url === 'external_link'">
            <validation-provider
              v-slot="{ errors }"
              name="type_url"
              vid="external_link"
              rules="required"
            >
              <b-form-input
                class="mt-2"
                v-model="form.url"
                placeholder="Cole a URL do termo de política e privacidade"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              @click="submit()"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Salvar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip, BFormTextarea
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Datepicker,
    ValidationProvider,
    extend,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isNewDocumentSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      form: {
        url: null,
        type_url: null
      },
      url: null,
      token: localStorage.getItem('accessToken'),
      fileError: null,
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    extend('required', required);
  },
  beforeMount() {
  },
  methods: {
    resetForm() {
      this.form = {
        url: null,
      }
    },
    submit() {
      const valid = this.$refs.refFormObserver.validate();

      const formData = new FormData();

      formData.append('url', this.form.url);
      formData.append('type_url', this.form.type_url);
      
      const apiUrl = process.env.VUE_APP_API + `/privacy-policies`;

      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.token,
        },
      };

      this.isBusy = true;

      axios
        .post(apiUrl, formData, axiosConfig)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso ao adicionar!',
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          console.log(response.data)
          this.$emit('close')
          this.resetForm()
          location.reload();
          this.isBusy = false;
        })
        .catch((error) => {
          console.error("Error fetching privacy policy:", error);
          this.isBusy = false;
        });
      console.log(apiUrl);
    },

    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.type !== 'application/pdf') {
          this.fileError = 'Arquivo invalido, selecione um arquivo .PDF';
        } else {
          try {
            const formData = new FormData();
            formData.append('file', file);
            await axios.post('/api/upload', formData);

            this.fileError = null;
          } catch (error) {
            if (error.response && error.response.status === 422) {
              this.fileError = 'Tipo de arquivo invalido';
            } else {
              console.error('Erro ao enviar arquivo:', error);
            }
          }
        }
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-congress-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
